@charset "utf-8";
@import "../node_modules/bulma/bulma.sass";

.App {
  max-width: 100vw;
  overflow-x: hidden; }

.card, .subtitle, .content {
  color: #000000b0; }



.pump-card, #existing-pump-table.card, .replacement-pump-table.card {
  background: #eee;
  width: 80vw;
  position: relative;
  z-index: 1;
  max-width: 1100px;
  margin: 40px auto;

  background-color: #eeeded;

  color:#000000b0 {}

  .card-content {

    padding: 1.5rem 0 0;
    .content {
      display: flex;
      align-items: flex-start;
      padding-bottom: 40px; }
    table {
      flex: 2;
      // width: auto
      tr:nth-of-type(2n) {
        background: rgb(255, 255, 255); }
      th {
        color: #000000de; } }
    .image-container {
      align-self: center;
      flex: 1;
      max-width: 30%;
      width: 25%;
      img {
        max-width: 70%;
        width: auto;
        height: auto; }
      .no-image {
        width: 50px;
        height: 50px;
        fill: #abc; } } } }
.pump-card {
  @media ( max-width: 1000px ) {
    width: 96vw;
    .card-content {
      .content {
        flex-direction: column;
        table {
          width: 100%; }
        .image-container {
          max-width: 80%;
          width: auto;
          margin-top: 30px;
          img {
            max-height: none; } } }
      .content > *:not(:last-child) {
        margin-bottom: 20px; } } } }


.segmented-tabs {
  width: 80vw;
  max-width: 100%;
  margin: 20px auto 0px;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  .bar {
    background: #e3e3e3;
    border-top: 1px solid #ddd;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse; }
  .link:hover, .link.active {
    border-top: 1px solid white;
    margin-top: -1px;
    border-radius: 4px 4px 0 0; }
  .tab {
    background: #fff; }
  canvas {
    margin: 0 auto;
    max-width: 100%;
    height: auto !important; }
  .react-pdf__Page__textContent {
    margin-top: -7px;
    @media ( max-width: 768px ) {
      display: none; } } }
@media ( max-width: 1000px ) {
  .segmented-tabs {
    width: 96vw; } }
