.segmentedtabsBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #e3e3e3;
  border-top: 1px solid #ddd;
  flex-wrap: wrap;
  padding: 0 37px;
}

.segmentedtabsBarLeft {
  display: flex;
  flex-direction: row;
}

.segmentedtabsBarRight {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.segmentedtabsLink {
  cursor: pointer;
  margin: 0px 5px;
  padding: 14px 20px 12px;
  font-size: 15px;
}

.segmentedtabsLink:nth-of-type(2n) {
  background: #dadada;
}
.segmentedtabsLink:hover {
  background: #fff9;
}
.segmentedtabsLink.active {
  background: #fff;
}
.tab {
  background: #fff;
}

.Ahlsell {
  padding: 0.5rem;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: 0.3s;
  background-color: #fff;
  margin-top: -1px;
}
/* .Ahlsell > a {
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: space-evenly; */
/* } */

.WholesaleButton {
  padding: 0.5rem;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: 0.3s;
  background-color: #fff;
  margin-top: -1px;
  /* margin: 0.5rem 0 0.5rem auto; */
}

.wholesaleButtonDiv {
  margin: 0.5rem 0 0.5rem auto;
}

.Ahlsell:hover {
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
}

.WholesaleButton:hover {
  padding: 0.5rem;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: 0.3s;
  background-color: #fff;
  margin-top: -1px;
}

.cart {
  font-size: 1.25rem;
  margin: 0rem 0.2rem 0rem 0.4rem;
}
.ahlsellImg {
  width: 4.5rem;
  height: 1rem;
}
.wholesaleImg {
  /* width: 4.5rem; */
  height: 2rem;
}
.ahlsellText {
  font-weight: 500;
  color: #4a4a4a;
}
.wholesaleText {
  font-weight: 500;
  color: #4a4a4a;
}

/* __________CHANGE PAGE ON PDF__________  */

.pageSelection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchPageButton {
  margin: 0.5rem 1rem;
  padding: 1rem;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
}
.switchPageButton:hover {
  cursor: pointer;
}

/* _________Responsive________ */
@media (max-width: 700px) {
  table tr th.adapterDivider,
  table tr td.adapterDivider {
    border-bottom: 2px solid #2d819d;
  }
  .adapter td img {
    height: 1rem;
    margin: 0rem;
    /* margin-top: 1rem; */
  }
  .ahlsellText {
    display: none;
  }
  .wholesaleText {
    display: none;
  }
  .Ahlsell {
    min-width: 7rem;
    display: flex;
  }
  .WholesaleButton {
    min-width: 7rem;
    display: flex;
  }
}

@media (max-width: 555px) {
  .segmentedtabsBar {
    padding: 0 10px;
  }
  .segmentedtabsBarLeft {
    padding: 0 5px;
  }
  .Ahlsell {
    display: flex;
  }
  .WholesaleButton {
    display: flex;
  }

  .adapter td img {
    margin: 0rem;
  }
  .ahlsellText {
    display: none;
  }
  .wholesaleText {
    display: none;
  }
}

@media (max-width: 450px) {
  .segmentedtabsLink {
    cursor: pointer;
    margin: 0px 5px;
    padding: 14px 10px 12px;
    font-size: 15px;
  }
}
